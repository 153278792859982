const getters = {
    userRole: (state) => state.userData.role || null,
    isAdmin: (state) => state.userData.role && state.userData.role === 'admin',
    isSales: (state) => state.userData.role && state.userData.role === 'sales-manager',
    isOrgAdmin: (state) => state.userData.role && state.userData.role === 'organization-admin',
    isOrgCustomer: (state) => state.userData.role && state.userData.role === 'customer',
    getCurrentFiat: (state) => state.userData.preferredCurrency,
}

export default getters
