import apiService from '@/utils/api-client'
import config from "@/utils/appConfig";

export default {
	// eslint-disable-next-line no-unused-vars
  getUsersList({ commit }, { count, skip, email, orgAdminId }) {
    return apiService.get('admin/users-list', { params: { count, skip, email, orgAdminId }, baseURL: config.VUE_APP_API_USERS_URL })
  },
  // eslint-disable-next-line no-unused-vars
  getUsersStatistics({ commit }, { id, startAt, endAt = +new Date(), tags = ["REPORT_GET", "MONITORING_ADD", "SANKEY_REPORT_CREATE"] }) {
    if (startAt) {
      const startAtDate = new Date(startAt);
      startAtDate.setHours(0, 0, 0, 0);
      startAt = startAtDate.getTime();
    }

    const endAtDate = new Date(endAt);
    endAtDate.setHours(23, 59, 59, 999);
    endAt = endAtDate.getTime();

    return apiService.get(`admin/users/${id}/usage-statistics`, { params: { startAt, endAt: !startAt ? undefined : endAt, tags }, baseURL: config.VUE_APP_API_USERS_URL })
  },
  // eslint-disable-next-line no-unused-vars
  setActiveUser({ commit }, data) {
    return apiService.put('admin/set-active-status', data, { baseURL: config.VUE_APP_API_USERS_URL })
  },
  // eslint-disable-next-line no-unused-vars
  getUser({ commit }, id) {
    return apiService.get(`admin/users/${id}`, { baseURL: config.VUE_APP_API_USERS_URL })
  },
  // eslint-disable-next-line no-unused-vars
  getUserActivityList({ rootGetters }, { id, sortField, sortOrder, count = 10, skip = 0 }) {
    const isOrgAdmin = rootGetters['user/isOrgAdmin']
    return apiService.get(`${isOrgAdmin ? 'organization' : 'admin'}/users/${id}/activity-logs`, { params: { count, skip, sortOrder, sortField },  baseURL: config.VUE_APP_API_USERS_URL })
  },
  editUser(ctx, { id, body }) {
    return apiService.patch(`admin/users/${id}`, body, { baseURL: config.VUE_APP_API_USERS_URL })
  },
  deleteUser(ctx, id) {
    return apiService.delete(`admin/users/${id}`, { baseURL: config.VUE_APP_API_USERS_URL })
  },
	// eslint-disable-next-line no-unused-vars
	sendInviteUser({ commit }, { email, role, activeTo = null, companyName, requestsTotal = null }) {
    return apiService.post('admin/send-invitation', { email, role, activeTo, companyName, requestsTotal, siteUrl: window.location.origin }, { baseURL: config.VUE_APP_API_USERS_URL })
  },
	// eslint-disable-next-line no-unused-vars
	resetPassword({ commit }, email) {
    return apiService.get(`users/reset-password/${email}`, { params: { siteUrl: window.location.origin }, baseURL: config.VUE_APP_API_USERS_URL })
  },
  // organization users
  getOrganizationsList({ commit }) {
    return apiService.get('admin/organization-list', { baseURL: config.VUE_APP_API_USERS_URL })
    .then((data) => {
      commit('SET_LIST_ORGANIZATIONS', data)
    })
    .catch((error) => {
      throw error
    })
  },
  sendInviteOrgUser({ commit }, email) {
    return apiService.post('organization/invite', { email }, { baseURL: config.VUE_APP_API_USERS_URL })
  },
  setActiveOrgUser({ commit }, data) {
    return apiService.patch(`organization/${data.id}`, data, { baseURL: config.VUE_APP_API_USERS_URL })
  },
  resetOrgUserPassword({ commit }, email) {
    return apiService.get(`organization/reset-password/${email}`, { params: { siteUrl: window.location.origin }, baseURL: config.VUE_APP_API_USERS_URL })
  },
  getOrgUserInfo({ commit }, id) {
    return apiService.get(`organization/${id}`, { baseURL: config.VUE_APP_API_USERS_URL })
  },
}
