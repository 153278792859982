import apiService from '@/utils/api-client'
import config from "@/utils/appConfig";

export default {
  // eslint-disable-next-line no-unused-vars
  getActiveCases: async function ({commit}, {
    title = undefined,
    from = undefined,
    to = undefined,
    coin = undefined,
    skip = undefined,
    count = undefined
  }) {
    try {
      let data;
      data = apiService.get('explorer/visualCases', {params: {from, to, title, archived: false, skip, count, blockchain: coin}, baseURL: config.VUE_APP_API_USERS_URL });
      // commit('ADD_HISTORY_ENTRY', { path: `api/tx/visualCases`})
      return data
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  getArchiveCases: async function ({commit}, {
    title = undefined,
    from = undefined,
    to = undefined,
    coin = undefined,
    skip = undefined,
    count = undefined
  }) {
    try {
      let data;
      data = apiService.get('explorer/visualCases', {params: {from, to, title, archived: true, skip, count, blockchain: coin}, baseURL: config.VUE_APP_API_USERS_URL});
      // commit('ADD_HISTORY_ENTRY', { path: `api/tx/visualCases`})
      return data
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  getCaseById: async function ({ commit }, id) {
    try {
      let data = await apiService.get(`explorer/visualCase/${id}`, { baseURL: config.VUE_APP_API_USERS_URL })
      return data
    } catch ({ response }) {
      if (response.data.code == '403') {
        commit('analytics/SET_MODAL_PRIVATE_CASE', response.data, { root: true })
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  async createCase(ctx, { search, graph, title, image, blockchain, isPrivate }) {
    const isOrgAdmin = ctx.rootGetters['user/isOrgAdmin']
    const payload = { search, graph, title, image, blockchain }
    if (isOrgAdmin) {
      payload.private = isPrivate
    }
    try {
      const data = apiService.post('explorer/visualCase', payload, { baseURL: config.VUE_APP_API_USERS_URL })
        return data
    } catch (error) {
        console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async visualCaseUpdates({ commit }, { addresses, mode, caseId }) {
    try {
      const data = apiService.post('explorer/visualCaseUpdates', { addresses, mode, caseId })
        return data
    } catch (error) {
        console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async deleteCase({ commit }, { caseIds }) {
    try {
      const data = apiService.delete(`explorer/visualCase`, { data: { ids: caseIds }, baseURL: config.VUE_APP_API_USERS_URL })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  editCase(ctx, { id, title, image, graph, isPrivate }) {
    const isOrgAdmin = ctx.rootGetters['user/isOrgAdmin']
    const payload = { id, title, image, graph }
    if (isOrgAdmin && config.VUE_APP_PERMISSION_MANAGER_FEATURE_FLAG) {
      payload.private = isPrivate
    }
    return apiService.patch(`explorer/visualCase`, payload, { baseURL: config.VUE_APP_API_USERS_URL })
  },

  // eslint-disable-next-line no-unused-vars
  casesToArchive({ commit }, { ids, status }) {
    return apiService.put('/explorer/visualCase', { ids, status: String(status) }, { baseURL: config.VUE_APP_API_USERS_URL })
  },
}
